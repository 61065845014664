class restApi{
    //Set domain name here
    static domain = "https://api.sight-eduplus.com";
    //static domain = "http://localhost:8000"
    static domainState = false;

    static checkNetwork(){
        if(!navigator.onLine){alert("You are offline"); return false}
        return true;
    }

    static PostApi(url, requestObject, isDomainUsed=restApi.domainState){      
        if(!isDomainUsed){
            url = restApi.domain + url;
        }
       // console.log(requestObject)
        return new Promise((resolve,reject)=>{
            fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestObject)
            })
            .then(Response=>Response.json())
            .then(result=>{
               resolve(result)
            })
            .catch(error=>{
                console.log(error,'this error')
                resolve({
                    isSuccessful: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        }) 
    }
    static getApi(url,isDomainUsed = restApi.domainState){
        if(!isDomainUsed){
            url = restApi.domain + url;
        }

        return new Promise((resolve,reject)=>{
            fetch(url,{
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
            })
            .then(Response=>Response.json())
            .then(result=>{
                resolve(result)
            })
            .catch(error=>{
                console.log(error,'this error')
                alert("Connectivity error. Please check internet network and try again")
                resolve({
                    isSuccessful: false,
                    message:"Connectivity error. Please check internet network and try again"
                });
            })
        })
    }
    static PostFormData(url, requestObject, shouldAddAuthorization=false, isDomainUsed=restApi.domainState){

        if(!isDomainUsed){
            url = restApi.domain + url;
        }

        return new Promise((resolve,reject)=>{
            let xhhtp = new XMLHttpRequest();
            xhhtp.onreadystatechange = function(){   
                //console.log(`state: ${this.readyState} | status: ${this.status}`)               
                if (this.readyState == 4 && this.status == 200){
                    let res = JSON.parse(xhhtp.responseText)
                    //console.log(res)  
                        resolve(res)                          
                }
                else if(this.status == 500 || this.status == 403){
                    reject({
                        isSuccessful: false
                    })
                }
            }
            xhhtp.open("POST", url, true); 
            if(shouldAddAuthorization){
                xhhtp.setRequestHeader("Authorization", "Bearer " + localStorage.getItem('eduplus_tkn'));
            }
            xhhtp.send(requestObject);
        })

    }

    static PostFormData2(url, requestObject, shouldAddAuthorization=false, isDomainUsed=restApi.domainState){
        if(!isDomainUsed){
            url = restApi.domain + url;
        }

        return fetch(url, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: shouldAddAuthorization ? {
                'Authorization': 'Bearer ' + localStorage.getItem('eduplus_tkn'),
                'Content-Type': 'multipart/form-data'
            } : {
                //'Content-Type': 'application/json'
            },
            body: requestObject
        });

    }
}
export default restApi;