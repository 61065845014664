import './Css/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageNotFound from './Views/PageNotFound';
import Home from './Views/Home';
import Form from './Views/Form';
import SubmittedForm from './Views/submittedForm';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<PageNotFound />} />
          <Route path="/form/:d" element={<Form />} />
          <Route path="/:d" element={<Home />} />
          <Route path="/completed" element={<SubmittedForm />}/>
          <Route path="/*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
