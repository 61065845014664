import {createSlice} from "@reduxjs/toolkit";

export const formSlice = createSlice({
    name: "form",
    initialState:{value: {}, answers: [], files: {}, },
    reducers: {
        setForm: (state,action)=>{
            state.value = action.payload;
        },
        setAnswer: (state, action)=>{
            const answers = state.answers;
            answers[action.payload.page][action.payload.index] = action.payload.value;

            state.answers = answers;
        },
        setAnswerStructure: (state, action)=>{
            state.answers = action.payload;
        },
        setFile: (state, action)=>{
            state.files[action.payload.page+''+action.payload.index] = action.payload;
        },
    }
})

export const {
    setForm, setAnswer, setAnswerStructure, setFile
} = formSlice.actions;

export default formSlice.reducer