import { useEffect, useState, useRef } from "react";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import { setAnswer, setAnswerStructure, setFile } from "../Redux/Reducers/formReducer";
import Api from '../HelperFunction/Api2';
import Loading from "../Components/loading";
import Base64FileInput from "../Components/base64";

const Form = () =>{
    const form = useSelector(store=>store.form.value);
    const answers = useSelector(store=>store.form.answers);
    const files = useSelector(store=>store.form.files);
    const {d} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [mainForm, setMainForm] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [page, setPage] = useState(null);
    const [showLoading, setShowLoading] = useState(false);
    const fileRef = useRef('file');

    useEffect(()=>{
        if(!form.id){
            navigate(`/${d}`);
        }
        else{
            const mainform = JSON.parse(form.form);

            const answers = [];
            const len = mainform.length;
            for(let i = 0; i < len; i++){
                const len2 = mainform[i].questions.length;
                answers[i] = [];
                for(let j = 0; j < len2; j++){
                    answers[i][j] = "";
                }
            }

            dispatch(setAnswerStructure(answers));
            setMainForm(mainform);
            setPage(mainform[currentPage]);
        }
    }, [form])

    useEffect(()=>{  
        if(mainForm.length > 0){
            // const mainform = JSON.parse(form.form);
            setPage(mainForm[currentPage]);   
        }     
    }, [currentPage])

    return (
        <>
            <Loading shouldShow={showLoading} />
            <div className="noteContentMain">
                {
                    page && (
                        <>
                            <div className="titleDiv"><h2>{page.title}</h2></div>
                            {
                                page.add_instruction && (
                                    <div className="instructionDiv content content2">
                                        <h4>Instruction:</h4>
                                        <p>{page.instruction}</p>
                                    </div>
                                )
                            }
                            <div className="allQuestionsDiv content content2">
                            {
                                page.questions.map((question,index)=>(
                                    <div className="questionDiv" key={question.id}>
                                        <p className={`${question.is_required?`required`: ''}`}>{index + 1} {question.question}</p>
                                        {question.answer_type === 'long_text' ? (
                                            <textarea value={answers[currentPage][index]} onChange={(e)=>dispatch(setAnswer({
                                                value: e.target.value,
                                                page: currentPage,
                                                index: index
                                            }))}></textarea>
                                        ) : (
                                            question.answer_type === 'select' ? (
                                                <select value={answers[currentPage][index]} onChange={(e)=>dispatch(setAnswer({
                                                    value: e.target.value,
                                                    page: currentPage,
                                                    index: index
                                                }))}>
                                                    <option value="">Select option</option>
                                                    {
                                                        question.options.map((option, index)=>(
                                                            <option value={option} key={index}>{option}</option>
                                                        ))
                                                    }
                                                </select>
                                            ) : (
                                            question.answer_type === 'file' ? (
                                                <Base64FileInput ref={fileRef} onChange={(e)=>dispatch(setFile({
                                                    file: fileRef.current.files[0].base64.split(',')[1],
                                                    fileName: fileRef.current.files[0].name,
                                                    type: fileRef.current.files[0].type,
                                                    catId: form.id,
                                                    page: currentPage,
                                                    index: index
                                                }))} />
                                            ) : (
                                                <input value={answers[currentPage][index]} placeholder="Enter your answer"  type="text" onChange={(e)=>dispatch(setAnswer({
                                                    value: e.target.value,
                                                    page: currentPage,
                                                    index: index
                                                }))} />
                                            )))
                                        }
                                    </div>
                                ))
                            }
                            </div>
                            <div className="buttonDiv content">
                                {
                                    currentPage > 0 && (
                                        <button className="previousBtn" onClick={()=>setCurrentPage(currentPage - 1)}>Previous</button>
                                    )
                                }
                                {
                                    currentPage < mainForm.length - 1 && (
                                        <button className="nextBtn" onClick={()=> setCurrentPage(currentPage + 1)}>Next</button>
                                    )
                                }
                                {
                                    currentPage === mainForm.length -1 && (
                                        <button className="nextBtn" onClick={()=>{
                                            // console.log(form.id, 'answers')
                                            setShowLoading(true);
                                            Api.PostApi('/form/submit-user-form',{
                                                files: Object.values(files),
                                                form: answers,
                                                form_id: form.id
                                            })
                                            .then(result=>{
                                                console.log(result, 'result')
                                                setShowLoading(false);
                                                if(result.isSuccessful){
                                                    navigate('/completed')
                                                }
                                                else{
                                                    alert(result.message)
                                                }
                                            })
                                        }}>Submit Form</button>
                                    )
                                }
                            </div>
                            
                        </>
                    )
                }
            </div>
        </>
    )
}

export default Form;