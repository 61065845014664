const SubmittedForm = () =>{
    return(
        <>
            <div className="noteContentMain">
                <div className="content content2">
                    <h3>Form submitted successfully</h3>
                    <a className="homepage" href="https://www.sight-eduplus.com">Go Back to HomePage</a>
                </div>
            </div>
        </>
    )
}

export default SubmittedForm;